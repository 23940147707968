<template>
  <div>
    <p><b>Заемщик</b> {{LastName}} {{FirstName}} {{MiddleName}} / {{DemandDate}}</p>
    <div>
      <p>Электронная подпись</p>
      <p>Дата и время подписи: {{DataConfirm | timeFromXtoDateTimeView}}</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: "signature",
    props:{
      LastName:{
        type:String,
        default:'',
      },
      FirstName:{
        type:String,
        default:'',
      },
      MiddleName:{
        type:String,
        default:'',
      },
      DemandDate:{
        type:String,
        default:'',
      },
      DataConfirm:{
        type:String,
        default:'',
      }
    }
  }
</script>

<style scoped>

</style>
